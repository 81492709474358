<template>
    <div class="lineChart" ref="lineChart"></div>
</template>
<script>
import echarts from 'echarts'
import {bytesToSize} from "@/utils";

export default {
    name: 'lineChart',
    props: {
        chartData: {
            type: Array,
        },
        unit: {
            type: String,
        },
        title: {
            type: String,
        },
        isLeftChart: {
            type: Boolean,
        },
    },
    data() {
        return {}
    },
    watch: {
        chartData() {
            this.setData()
        },
        title() {
            this.setData()
        },
    },
    mounted() {
        this.mycharts()
    },
    beforeDestory() {
        echarts.dispose(this.myChart)
        this.myChart = null
    },
    methods: {
        mycharts() {
            this.myChart = echarts.init(this.$refs.lineChart, 'macarons')
            // 绘制图表
            this.myChart.setOption({
                xAxis: {
                    type: 'category',
                    data: [],
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#9AA0BA',
                            fontSize: '12',
                        },
                    },
                },
                yAxis: {
                    name: '单位：' + '',
                    type: 'value',
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        formatter: (val) => {
                            if (val < 1) {
                                return val + 'B'
                            }
                            return bytesToSize(val, 0)
                        },
                        textStyle: {
                            color: '#9AA0BA',
                            fontSize: '10',
                        },
                    },
                    nameTextStyle: {
                        fontSize: '12',
                        color: '#6B748E',
                    },
                },
                series: [
                    {
                        name: this.title,
                        type: 'line',
                        data: [],
                        barWidth: '20',
                        itemStyle: {
                            // 圆角
                            barBorderRadius: [4, 4, 0, 0],
                        },
                        //柱图渐变色
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            this.isLeftChart
                                ? [
                                    {offset: 0, color: '#64B1FF'},
                                    {offset: 1, color: '#3986FE'},
                                ]
                                : [
                                    {offset: 0, color: '#2CEBC0'},
                                    {offset: 1, color: '#17D1A8'},
                                ]
                        ),
                        nameTextStyle: {
                            fontSize: '12',
                            color: '#6B748E',
                        },
                    },
                ],
                grid: {
                    left: 20,
                    right: 30,
                    bottom: 10,
                    top: 60,
                    containLabel: true,
                },
            })
            //让图表自适应
            window.addEventListener('resize', () => {
                this.myChart.resize() // myChart 是实例对象
            })
        },
        setData() {
            if (!this.chartData) return
            this.myChart.setOption({
                title: {
                    text: this.title, textStyle: {
                        fontSize: '16',
                        color: '#222B45',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (data) => {
                        return data[0].name + '<br/>' + this.title + ':' + bytesToSize(parseFloat(data[0].value))
                    },
                },
                xAxis: {
                    data: this.chartData.map((item) => item.xInfo),
                },
                yAxis: {
                    name: '单位：' + this.unit,
                },
                series: [
                    {
                        data: this.chartData.map((item) => parseFloat(item.yInfo).toFixed(2)),
                    },
                ],
            })
        },
    },
}
</script>

<style lang="sass" scoped>
.lineChart
    width: 100%
    height: 100%
</style>
