<template>
    <div class="overView page-container">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>概览</el-breadcrumb-item>
        </el-breadcrumb>
        <!--        <span class="tips">{{ info.explain }}</span>-->
        <el-alert class="alert" title="特别提醒：概览数据平均延迟1-2小时，不作为计量数据，仅作参考。" type="warning" show-icon
                  :closable="false"></el-alert>
        <div class="info" v-loading="overviewLoading">
            <el-row :gutter="10">
                <el-col :lg="{ span: isAdmin ? '5-25' : 4 }" v-for="(item, index) in cardArr" :key="index">

                    <el-card class="cards">
                        <div class="detail">
                            <div class="explain">{{ cardArr[index] }}
                                <el-tooltip effect="dark" :content="hoverTip" placement="top" v-if="index == 2">
                                    <i class="el-icon-info"></i>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="包含碎片数量" placement="top" v-if="index == 1">
                                    <i class="el-icon-info"></i>
                                </el-tooltip>
                            </div>

                            <div v-if="isAdmin" class="overview_item">
                                <div v-if="index == 0 || index == 1 || index == 4 || index == 6" class="overview_info">
                                    <span :class="['num', colorClassArr[index]]">
                                        {{ formatterFullNum(numLabelArr[index]) }}
                                    </span>
                                    <span class="unit">{{ formatterFullUnit(numLabelArr[index]) }}</span>
                                </div>
                                <div v-else>
                                    <span :class="['num', colorClassArr[index]]">{{
                                            formatterNumValue(numLabelArr[index])
                                        }} </span>
                                    <span class="unit">{{ formatterUnitValue(numLabelArr[index]) }}</span>
                                </div>
                                <i :class="['iconfont2', imgArr[index]]"></i>
                            </div>

                            <div v-else class="overview_item">
                                <div v-if="index == 0 || index == 1 || index == 4" class="overview_info">
                                    <span :class="['num', colorClassArr[index]]">{{
                                            formatterFullNum(numLabelArr[index])
                                        }} </span>
                                    <span class="unit">{{ formatterFullUnit(numLabelArr[index]) }}</span>
                                </div>
                                <div v-else class="overview_info">
                                    <span :class="['num', colorClassArr[index]]">{{
                                            formatterNumValue(numLabelArr[index])
                                        }} </span>
                                    <span class="unit">{{ formatterUnitValue(numLabelArr[index]) }}</span>
                                </div>
                                <i :class="['iconfont2', imgArr[index]]"></i>
                            </div>
                        </div>
                    </el-card>

                </el-col>
            </el-row>

            <!-- <div class="list">
              <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-01.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">{{ info.bucketCount }}</div>
                  <div class="explain">存储桶总数量</div>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-02.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">{{ info.objectCount }}</div>
                  <div class="explain">文件总数量</div>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-03.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">{{ info.storageCount }}</div>
                  <div class="explain">存储总量</div>
                </div>
              </div> -->
            <!-- <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-04.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">{{ info.storageOfDateCount }}</div>
                  <div class="explain">本月日均存储量</div>
                </div>
              </div> -->
            <!-- <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-05.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">{{ info.visitCount }}</div>
                  <div class="explain">本月总访问流量</div>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-06.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">
                    {{ info.allRequest }}
                  </div>
                  <div class="explain">本月总请求数</div>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img src="../assets/img/overView/icon-07.png" alt="" />
                </div>
                <div class="details">
                  <div class="num">{{ info.downloadCount }}</div>
                  <div class="explain">本月总下载量</div>
                </div>
              </div>
              <template v-if="isAdmin">
                <div class="item">
                  <div class="icon">
                    <img src="../assets/img/overView/icon-08.png" alt="" />
                  </div>
                  <div class="details">
                    <div class="num">{{ info.userCount }}</div>
                    <div class="explain">用户数</div>
                  </div>
                </div>
                <div class="item">
                  <div class="icon">
                    <img src="../assets/img/overView/icon-10.png" alt="" />
                  </div>
                  <div class="details">
                    <div class="num">{{ info.standardstorageCount }}</div>
                    <div class="explain">标准存储总量</div>
                  </div>
                </div>
                <div class="item">
                  <div class="icon">
                    <img src="../assets/img/overView/icon-11.png" alt="" />
                  </div>
                  <div class="details">
                    <div class="num">{{ info.tapestorageCount }}</div>
                    <div class="explain">归档存储总量</div>
                  </div>
                </div>
                <div class="item">
                  <div class="icon">
                    <img src="../assets/img/overView/icon-12.png" alt="" />
                  </div>
                  <div class="details">
                    <div class="num">{{ info.glacierstorageCount }}</div>
                    <div class="explain">深度归档存储总量</div>
                  </div>
                </div>
              </template>
            </div> -->
        </div>
        <div class="storeInfo">
            <div class="tab">
                <div v-if="storageClassList.indexOf('STANDARD') > -1" class="type" :class="{ active: type == 0 }" @click="changeType(0)">
                    标准存储
                    <el-tooltip effect="dark" content="适用于文件频繁访问" placement="top">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </div>
                <div v-if="storageClassList.indexOf('ARCHIVE') > -1" class="type" :class="{ active: type == 1 }" @click="changeType(1)">
                    归档存储
                    <el-tooltip effect="dark" content="适用于文件归档，极少访问" placement="top">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </div>
                <div v-if="storageClassList.indexOf('GLACIER') > -1" class="type" :class="{ active: type == 2 }" @click="changeType(2)">
                    深度归档存储
                    <el-tooltip effect="dark" content="适用于文件深度归档，长期不访问" placement="top">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </div>
            </div>
            <div class="content">
                <div class="storage-total">
                    <i class="iconfont2 iconcunchuzongliang"></i>
                    <span class="center explain">存储总量</span>
                    <span class="detail center">
            <span class="num num-bucket">{{ formatterNumValue(storageSum.all) }}</span>
            <span class="unit unit-center">{{ formatterUnitValue(storageSum.all) }}</span>
          </span>

                    <!-- <div class="item">
                      <div class="icon">
                        <img src="../assets/img/overView/icon-04.png" alt="" />
                      </div>
                      <div class="details">
                        <div class="num">
                          {{ storageSum.average }}
                        </div>
                        <div class="explain">本月日均存储量</div>
                      </div>
                    </div> -->
                </div>
                <div class="date">
                    <el-date-picker v-model="date[type]" type="daterange" range-separator="至" start-placeholder="开始日期"
                                    end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    :clearable="false" class="picker" prefix-icon="iconfont iconriqi">
                    </el-date-picker>
                    <el-button style="margin-left: 10px;" size="small" type="primary" @click="getGraphData('1')" class="queryBtn">查询</el-button>
                    <el-button size="small" type="text" @click="downloadResult()" class="downBtn">下载查询结果</el-button>
                </div>
                <div class="chartSpace">
                    <div class="chartBox">
                        <line-chart :chartData="graphData.memoryData" :unit="graphData.memoryUnit" :isLeftChart="true"
                                    title="存储总量">
                        </line-chart>
                    </div>
                    <div class="chartBox" v-if="type == 0">
                        <line-chart :chartData="graphData.downloadData" :unit="graphData.downloadUnit"
                                    title="下载量"></line-chart>
                    </div>
                    <div class="chartBox" v-else>
                        <line-chart :chartData="graphData.recaptionData" :unit="graphData.recaptionUnit"
                                    title="取回量"></line-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import storage from '@/utils/storage'
import moment from 'moment'
import lineChart from './components/lineChart'
import {bytesToSize} from "@/utils";

export default {
    name: 'overView',
    components: {
        lineChart,
    },
    computed: {
        isAdmin () {
            return true
        },
        storageSum() {
            if (this.type == 0) {
                return {
                    all: this.info.standardRegionSpace,
                    average: this.info.standardstorageOfDateCount,
                }
            } else if (this.type == 1) {
                return {
                    all: this.info.archiveRegionSpace,
                    average: this.info.tapestorageOfDateCount,
                }
            } else {
                return {
                    all: this.info.glacierRegionSpace,
                    average: this.info.glacierstorageOfDateCount,
                }
            }
        },
        colorClassArr() {
            if (this.isAdmin) {
                return this.adminColorClassArr
            } else {
                return this.usersColorClassArr
            }
        },
        cardArr() {
            var cardsArr = ['存储桶总数量', '文件总数量', '存储总量', '本月总访问流量', '本月总请求数', '本月总下载量']
            if (this.isAdmin) {
                cardsArr.push('用户数')
                // cardsArr.push('标准存储总量')
                // if (this.hasArchive == '1') {
                //     cardsArr.push('归档存储总量')
                // }
                // if (this.hasGlacier == '1') {
                //     cardsArr.push('深度归档存储总量')
                // }
            }
            return cardsArr
        },
        imgArr() {
            if (this.isAdmin) {
                return this.adminImgArr
            } else {
                return this.usersImgArr
            }
        },
        //底层磁带+蓝光：标准存储、归档存储和深度归档存储的总量(不含EC、副本等)
        //底层磁带：标准存储和归档存储的总量(不含EC、副本等)
        //底层蓝光：标准存储和深度归档存储的总量(不含EC、副本等)
        hoverTip() {
            if (this.hasArchive == '1' && this.hasGlacier == '1') {
                return '标准存储、归档存储和深度归档存储的总量(不含EC、副本等)'
            } else {
                if (this.hasArchive == '1') {
                    return '标准存储和归档存储的总量(不含EC、副本等)'
                } else {
                    return '标准存储和深度归档存储的总量(不含EC、副本等)'
                }
            }
        },
    },
    data() {
        return {
            overviewLoading: false,
            regionList: [],
            info: {},
            type: 0,
            date: [],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6
                },
            },
            graphData: {},
            hasStandard: storage.get('hasStandard'),
            hasArchive: storage.get('hasArchive'),
            hasGlacier: storage.get('hasGlacier'),
            usersColorClassArr: ['num-bucket', 'num-object', 'num-storage', 'num-visit', 'num-all', 'num-down'],
            adminColorClassArr: ['num-bucket', 'num-object-admin', 'num-object', 'num-visit-admin', 'num-storage', 'num-visit', 'num-all', 'num-down', 'num-tapes-storage', 'num-glacier-storage'],
            usersImgArr: ['iconBucketzongshuliang', 'iconwenjianzongshuliang', 'iconcunchuzongliang', 'iconbenyuezongfangwenliuliang', 'iconbenyuezongqingqiucishu', 'iconbenyuezongxiazailiang'],
            adminImgArr: ['iconBucketzongshuliang', 'iconwenjianzongshuliang', 'iconcunchuzongliang', 'iconbenyuezongfangwenliuliang', 'iconbenyuezongqingqiucishu', 'iconbenyuezongxiazailiang', 'iconyonghushu', 'iconbiaozhuncunchuzongliang', 'iconguidangcunchuzongliang', 'iconshenduguidangcunchuzongliang'],
            numLabelArr: [],
            storageClassList: []
        }
    },
    created() {
        this.getInfo()
        this.initDate()
        // this.getGraphData('')
    },
    methods: {
        // ...mapActions('overView', ['getInfo', 'getGraph', 'download']),
        getInfo() {
            this.overviewLoading = true
            this.$postJson('/dashboard/overview')
                .then(res => {
                    if (res.code === '200') {
                        let regionId = storage.get('REGION_ID')
                        this.regionList = res.data.regionList
                        this.info = res.data.regionList.find(i => i.regionId === regionId) || {}
                        this.storageClassList = this.info.storageClassList || []
                        if (!this.isAdmin) {
                            this.numLabelArr.push(this.info.bucketCount)
                            this.numLabelArr.push(this.info.objects)
                            this.numLabelArr.push(this.info.usedSize)
                            this.numLabelArr.push(this.info.monthTxBytes)
                            this.numLabelArr.push(this.info.monthRequests)
                            this.numLabelArr.push(this.info.monthReceivedBytes)
                        } else {
                            this.numLabelArr.push(this.info.bucketCount)
                            this.numLabelArr.push(this.info.objects)
                            this.numLabelArr.push(this.info.usedSize)
                            this.numLabelArr.push(this.info.monthTxBytes)
                            this.numLabelArr.push(this.info.monthRequests)
                            this.numLabelArr.push(this.info.monthReceivedBytes)
                            this.numLabelArr.push(this.info.accountCount)
                            this.numLabelArr.push(this.info.standardstorageCount)
                            this.numLabelArr.push(this.info.tapestorageCount)
                            this.numLabelArr.push(this.info.glacierstorageCount)
                        }
                    }
                })
            .finally(() => {
                this.overviewLoading = false
            })
        },
        getGraph() {
        },
        download() {
        },
        getInfoData() {
            this.getInfo().then((res) => {
                this.info = res.data
                this.info.allRequest = parseFloat(this.info.readCount) + parseFloat(this.info.writeCount) + '次'

                this.numLabelArr = []
                if (!this.isAdmin) {
                    this.numLabelArr.push(this.info.bucketCount)
                    this.numLabelArr.push(this.info.objectCount)
                    this.numLabelArr.push(this.info.storageCount)
                    this.numLabelArr.push(this.info.visitCount)
                    this.numLabelArr.push(this.info.allRequest)
                    this.numLabelArr.push(this.info.downloadCount)
                } else {
                    this.numLabelArr.push(this.info.bucketCount)
                    this.numLabelArr.push(this.info.objectCount)
                    this.numLabelArr.push(this.info.storageCount)
                    this.numLabelArr.push(this.info.visitCount)
                    this.numLabelArr.push(this.info.allRequest)
                    this.numLabelArr.push(this.info.downloadCount)
                    this.numLabelArr.push(this.info.userCount)
                    this.numLabelArr.push(this.info.standardstorageCount)
                    this.numLabelArr.push(this.info.tapestorageCount)
                    this.numLabelArr.push(this.info.glacierstorageCount)
                }
            })
        },
        initDate() {
            let start = moment().subtract(6, 'days').format('YYYY-MM-DD')
            let end = moment().format('YYYY-MM-DD')
            this.date = [
                [start, end],
                [start, end],
                [start, end],
            ]
            this.getGraphData()
        },
        getGraphData() {
            this.$postJson('/dashboard/listStatisticsSummary', {
                startTime: this.date[this.type][0],
                endTime: this.date[this.type][1],
                storageClass: ['STANDARD', 'ARCHIVE', 'GLACIER'][this.type]
            })
            .then(res => {
                if (res.code === '200') {
                    let graphData = {

                    }

                    graphData.memoryData = res.data.map(item => ({
                        showYInfo: '',
                        xInfo: item.date,
                        yInfo: item.size
                    }))
                    graphData.memoryUnit = ''

                    graphData.downloadData = res.data.map(item => ({
                        showYInfo: '',
                        xInfo: item.date,
                        yInfo: item.txBytes
                    }))
                    graphData.downloadUnit = ''

                    graphData.recaptionData = res.data.map(item => ({
                        showYInfo: '',
                        xInfo: item.date,
                        yInfo: item.txBytes
                    }))
                    graphData.recaptionUnit = ''

                    this.graphData = graphData
                }
            })
        },
        downloadResult() {
            this.$download('/dashboard/downloadStatisticsSummary', {
                startTime: this.date[this.type][0],
                endTime: this.date[this.type][1],
                storageClass: ['STANDARD', 'ARCHIVE', 'GLACIER'][this.type]
            })
            // this.$postJson('/dashboard/downloadStatisticsSummary',{
            //     startTime: this.date[this.type][0],
            //     endTime: this.date[this.type][1],
            //     storageClass: ['STANDARD', 'ARCHIVE', 'GLACIER'][this.type]
            // })
            // .then((res) => {
            //     const link = document.createElement('a')
            //     const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            //     link.style.display = 'none'
            //     link.href = URL.createObjectURL(blob)
            //     let time = moment().format('YYYYMMDDHHmmss')
            //     link.setAttribute('download', `${time}.xls`)
            //     document.body.appendChild(link)
            //     link.click()
            //     document.body.removeChild(link)
            // })
        },
        changeType(index) {
            this.type = index
            this.getGraphData('')
        },
        formatterNumValue(info = '') {
            return bytesToSize(info).split(' ')[0] || 0
        },
        formatterUnitValue(info = '') {
            return bytesToSize(info).split(' ')[1]
        },
        formatterFullNum(info = '') {
            info += ''
            if (info) {
                if (info.length > 5 && info.length <= 9) {
                    return Math.trunc(parseFloat(info) / 10000) + '+'
                } else if (info.length > 9) {
                    return Math.trunc(parseFloat(info) / 100000000) + '+'
                } else {
                    return parseFloat(info)
                }
            } else {
                return 0
            }
        },
        formatterFullUnit(info = '') {
            info = info + ''
            if (info) {
                if (info.length > 5 && info.length <= 9) {
                    return '万'
                } else if (info.length > 9) {
                    return '亿'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.detail {
    font-size: 14px;

    .explain {
        color: #8484af;
        margin-bottom: 16px;
    }

    .num {
        font-size: 32px;
        font-weight: bold;
        font-family: D-DIN DIN;
    }

    .unit {
        color: #222b45;
        // margin-left: -6px;
        margin-top: 10px;
    }

    .num-bucket {
        color: #006bff;
    }

    .num-object {
        color: #16c8f0;
    }

    .num-storage {
        color: #3fe9c2;
    }

    .num-visit {
        color: #fdd53e;
    }

    .num-all {
        color: #ffac5f;
    }

    .num-down {
        color: #ff9c3c;
    }

    .num-object-admin {
        color: #38abff;
    }

    .num-visit-admin {
        color: #53e2ff;
    }

    .num-tapes-storage {
        color: #fd7726;
    }

    .num-glacier-storage {
        color: #ff563b;
    }

    .overview_item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .overview_info {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.cards {
    // height: 128px;
    // position: relative;
    padding: 5px;

    .iconfont2 {
        // position: absolute;
        // right: 30px;
        // bottom: 20px;
        font-size: 48px;
        color: #9aa0ba;
    }
}

.tips {
    height: 32px;
    width: auto;
    padding: 6px 20px;
    line-height: 32px;
    font-size: 14px;
    background: rgba(0, 107, 255, 0.1);
    color: #006bff;
    border-radius: 20px;
}

.info {
    margin-top: 16px;

    .el-row {
        //lg：大屏幕 大桌面显示器 (≥1200px) span默认24列 el-col的lg属性来改变为25列
        .el-col-lg-5-25 {
            width: 20%;
        }

        .el-col {
            margin-bottom: 20px;
        }
    }
}

.storeInfo {
    background: #fff;
    margin-top: 12px;

    .tab {
        height: 42px;
        border-bottom: 2px solid #e4e4e4;
        display: flex;
        align-items: center;

        .type {
            height: 100%;
            width: 140px;
            text-align: center;
            line-height: 44px;
            font-size: 14px;
            font-weight: bold;
            margin-left: 15px;
            cursor: pointer;

            &.active {
                color: #006bff;
                border-bottom: 2px solid #006bff;
            }

            .el-icon-info {
                margin-left: 3px;
            }
        }
    }

    .content {
        width: 100%;
        padding: 0 10px 40px 10px;

        .storage-total {
            display: flex;
            margin: 32px;
            height: 96px;
            background: #f3f7fc;

            .iconfont {
                font-size: 48px;
                margin-top: 24px;
                margin-left: 32px;
                color: #3986fe;
            }

            .explain {
                font-size: 16px;
                color: #222b45;
            }

            .center {
                margin-left: 24px;
                align-items: center;
                display: flex;
            }

            .unit-center {
                margin-top: 15px;
                margin-left: 5px;
            }
        }

        .picker {
            width: 290px;
            height: 36px;
        }
    }

    .list {
        margin: 10px 0;
    }

    .chartSpace {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .chartBox {
            width: 48%;
            height: 320px;
        }
    }

    .date {
        /deep/ .el-button-primary {
            padding: 11px 20px;
        }

        .queryBtn {
            width: 68px;
            height: 36px;
        }

        .downBtn {
            width: 124px;
            height: 36px;
            background: rgba(0, 107, 255, 0.1);

            body.el-button--text {
                color: #006bff;
            }
        }
    }

    /deep/ {
        .el-date-editor {
            .el-range-separator {
                color: #9aa0ba;
                padding: 0;
                line-height: 28px;
            }

            .el-range__icon {
                color: #006bff;
                line-height: 28px;
            }

            .el-range-input {
                color: #222b45;
            }
        }
    }
}

// 1366
@media screen and (max-width: 1366px) {
    .overView {
        .num {
            font-size: 23px;
            font-weight: bold;
            font-family: D-DIN DIN;
        }

        .unit {
            font-size: 10px;
            color: #222b45;
            margin-top: 8px;
        }

        .iconfont {
            font-size: 34px;
            margin-left: 10px;
        }
    }
}

// 大于1366
@media screen and (min-width: 1367px) {
    .overView {
        .unit {
            margin-top: 15px;
        }
    }
}
.storage-total {
    .iconcunchuzongliang {
        font-size: 48px;
        margin-top: 24px;
        margin-left: 32px;
        color: #3986fe;
    }
}

</style>
